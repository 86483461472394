import { CommonAPIService } from './CommonHandler';

export class AgentAPIHandler {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  get(router) {
    const url = `/api/agents`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  create(agent, router) {
    const url = `/api/agents`;
    return this.CommonAPIService.postCall(url, agent, router);
  }
  delete(id, router) {
    const url = `/api/agents/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }
  update(id, agent, router) {
    const url = `/api/agents/${id}`;
    return this.CommonAPIService.putCall(url, agent, router);
  }
}

export default AgentAPIHandler;
