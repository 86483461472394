<template>
  <div>
    <h3>Dealer Signup Lookup</h3>
    <h4>Select a dealer that has signed up from the select box</h4>
    <card>
      <label>Select a dealer from the list below</label>
      <select @change="getDealer" v-model="selectedDealer" class="form-control">
        <option v-for="dealer of dealerSelect" v-bind:key="dealer">
          {{ dealer }}
        </option>
      </select>
    </card>
    <div v-if="selectedDealer">
      <card>
        <h4 class="card-title">Dealer Information</h4>
        <h5><button class="btn btn-primary" @click="dealerInsert">Insert Dealer into PCRS</button></h5>
        <p class="card-category"></p>
        <p><label>
          Dealer Name: {{ displayDealer.dealer.dealer_name }}
        </label></p>
        <p><label>
          Address1: {{ displayDealer.dealer.address1 }}
        </label></p>
        <p><label>
          Address2: {{ displayDealer.dealer.address2 }}
        </label></p>
        <p><label>
          City: {{ displayDealer.dealer.city }}
        </label></p>
        <p><label>
          State: {{ displayDealer.dealer.state }}
        </label></p>
        <p><label>
          Zip: {{ displayDealer.dealer.zip }}
        </label></p>
        <p><label>
          Dealer Contact First: {{ displayDealer.dealer.first_name }}
        </label></p>
        <p><label>
          Dealer Contact Last: {{ displayDealer.dealer.last_name }}
        </label></p>
        <p><label>
          Dealer Contact Phone: {{ displayDealer.dealer.phone }}
        </label></p>
        <p><label>
          Dealer Contact Email: {{ displayDealer.dealer.email }}
        </label></p>
      </card>
      <card>
        <h4 class="card-title">Agent Information</h4>
        <p><label>Agent Name: {{displayDealer.agent.name }}</label></p>
        <p><label>Address1: {{displayDealer.agent.address1 }}</label></p>
        <p><label>Address2: {{displayDealer.agent.address2 }}</label></p>
        <p><label>Agency: {{displayDealer.agent.agency }}</label></p>
        <p><label>City: {{displayDealer.agent.city }}</label></p>
        <p><label>State: {{displayDealer.agent.state }}</label></p>
        <p><label>Zip: {{displayDealer.agent.zip }}</label></p>
      </card>
      <card>
        <h4 class="card-title">PCRS Users</h4>
        <p class="card-category"></p>
        <div v-for="user of displayDealer.pcrs_users" v-bind:key="user.id">
          <card>
            <p><label>Email: {{ user.email }}</label></p>
            <p><label>First: {{ user.first_name }}</label></p>
            <p><label>Last: {{ user.last_name }}</label></p>
            <p><label>User Role: {{ user.user_role }}</label></p>
          </card>
        </div>
      </card>
      <card>
        <h4 class="card-title">Selected Menu Options</h4>
        <p class="card-category"></p>
        <div class="col" v-for="menu of displayDealer.menu_options" v-bind:key="menu.id">
          Menu Option: {{ menu.label }}
        </div>
      </card>
      <card>
        <h4 class="card-title">Pinnacle Product Information</h4>
        <p class="card-category"></p>
        <div class="col" v-for="product of displayDealer.session_pinnacle_products" v-bind:key="product.id">
          Product Name: {{ product.name }} - Commission: {{ product.sessions_to_pinnacle_products.commission }}
        </div>
      </card>
      <card>
        <h4 class="card-title">Hard Pack Information</h4>
        <p class="card-category"></p>
        <div class="row" v-for="product of displayDealer.session_hard_packs" :key="product.id">
          <div class="col-sm-12">
            <div class="col">
              Name: {{ product.name }} - Commission: {{ product.sessions_to_hard_packs.commission }}
            </div>
          </div>
        </div>
      </card>
      <card>
        <h4 class="card-title">Soft Pack Information</h4>
        <p class="card-category"></p>
        <div class="row" v-for="product of displayDealer.session_soft_packs" :key="product.id">
          <div class="col-sm-12">
            <div class="col">
              Name: {{ product.name }} - Commission: {{ product.sessions_to_soft_packs.commission }}<br>
            </div>
          </div>
        </div>
      </card>
      <card>
        <h4 class="card-title">NCB</h4>
        <p class="card-category"></p>
        <div v-for="product of displayDealer.session_ncbs" v-bind:key="product.id">
          <div class="col">
            Name: {{ product.name }}<br>
          </div>
        </div>
      </card>
      <card>
        <h4 class="card-title">Files</h4>
        <p class="card-category">Files Uploaded on Signup - click to download</p>
        <div v-for="file in this.files" v-bind:key="file">
          <p><label @click="downloadFile(file)">
            {{ file }}
          </label></p>
        </div>
      </card>
    </div>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <span>{{ success_txt }}</span>
      <span slot="footer" class="dialog-footer">
              <button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
            </span>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog } from 'element-ui';
import ProductsAPIService from "../../../../servicehandlers/ProductsAPIService";
import filesAPIHandler from "../../../../servicehandlers/FilesAPIHandler";
import DealerAPIService from "../../../../servicehandlers/DealerAPIService";

const productsAPIService = new ProductsAPIService();
const FilesAPIHandler = new filesAPIHandler();
const dealerAPIHandler  = new DealerAPIService();

export default {
  name: "DealerSignupLookup",
  components: {
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      success_txt: null,
      error_txt: null,
      allProducts: [],
      dealerSelect: [],
      selectedDealer: null,
      displayDealer: {},
      files: {},
      modals: {
        error: false,
        success:false,
      }
    }
  },
  mounted() {
    this.getAllProducts();
  },
  methods: {
    async downloadFile(file) {
      let response = await FilesAPIHandler.getDealerFile(this.$router, this.selectedDealer, file);
      let url = window.URL.createObjectURL(response.data);
      let fileLink = document.createElement('a');

      fileLink.href = url;
      fileLink.setAttribute('download', file);
      document.body.appendChild(fileLink)

      fileLink.click();
    },
    dealerInsert() {
      this.success_txt = 'Inserting dealing into PCRS';
      return dealerAPIHandler.insertDealer(this.displayDealer, this.$router)
      .then((response) => {
        this.success_txt = 'Successfully created dealer'
        this.openModal('success');
      })
      .catch((error) => {
        this.error_txt = 'Error creating Dealer: ' + error;
        this.openModal('error');
      });
    },
    getFiles() {
      return FilesAPIHandler.getDealerFiles(this.$router, this.selectedDealer)
      .then((files) => {
        if (files === undefined) {
          this.files = {};
        }
        else {
          this.files = files;
        }
      });
    },
    getDealer() {
      for (const dealer of this.allProducts) {
        if (dealer.dealer.dealer_name === this.selectedDealer) {
          this.displayDealer = dealer;
        }
      }
      this.getFiles();
    },
    getAllProducts() {
      return productsAPIService.getAllProductSets(this.$router)
      .then((response) => {
        for (const item of response) {
          this.allProducts.push(item);
          this.dealerSelect.push(item.dealer.dealer_name);
        }
      })
      .catch((error) => {
      })
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
  }
}
</script>

<style scoped>

</style>
