<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-4 text-center">
          <datepicker
            class="editable editable-click"
            :bootstrap-styling="true"
            :format="customFormatter"
            placeholder="Start Date"
            v-model="start_date">
          </datepicker>
        </div>
        <div class="col-sm-4 text-center">
          <datepicker
            class="editable editable-click"
            :bootstrap-styling="true"
            :format="customFormatter"
            placeholder="End Date"
            v-model="end_date">
          </datepicker>
        </div>
        <div class="col-sm-4">
          <button class="btn-primary" @click="getORICActivationReport">ORIC Reports</button>
        </div>
      </div>
    </card>
    <card v-if="activationRan">
      <div v-for="file of filenames" v-bind:key="file" class="row">
        <div class="col text-center'">
          <button class="btn btn-primary" @click="downloadFile(file)">{{ file }}</button>
        </div>
      </div>
    </card>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <span>{{ success_txt }}</span>
      <span slot="footer" class="dialog-footer">
              <button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
            </span>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Datepicker from 'vue-moment-datepicker';
import moment from 'moment';
import ReportsAPIHandler from "../../../../servicehandlers/ReportsAPIHandler";
import FilesAPIHandler from "../../../../servicehandlers/FilesAPIHandler";
const reportsAPIHandler = new ReportsAPIHandler();
const filesAPIHandler = new FilesAPIHandler();
import { Dialog } from 'element-ui';
export default {
  name: "PCMIReportsTranslation",
  components: {
    [Dialog.name]: Dialog,
    Datepicker
  },
  data() {
    return {
      claim_headers_csv_path: null,
      claim_details_csv_path: null,
      excel_claim_file: null,
      oric_csv_activation_filename: null,
      oric_csv_cancellation_filename: null,
      oric_excel_activation_filename: null,
      oric_excel_cancellation_filename: null,
      spinning: false,
      isRan: false,
      activationRan: false,
      start_date: null,
      filenames: null,
      end_date: null,
      success_txt: null,
      error_txt: null,
      modals: {
        error: false,
        success:false,
      }
    }
  },
  methods: {
    downloadFile(filename) {
      this.spinning = true;
      return filesAPIHandler.getFile(filename, this.$router)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename); // Set the download attribute to the filename you want
          document.body.appendChild(link);
          link.click();
          this.spinning = false;
        })
        .catch((error) => {
          this.spinning = false;
          this.error_txt = 'File Error: ' + error;
          this.openModal('error');
        });
    },
    getCSVActivationFile() {
      this.spinning = true;
      return filesAPIHandler.getFile(this.oric_csv_activation_filename, this.$router)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'oric_csv_activation_file.csv'); // Set the download attribute to the filename you want
          document.body.appendChild(link);
          link.click();
          this.spinning = false;
        })
        .catch((error) => {
          this.spinning = false;
          this.error_txt = 'File Error: ' + error;
          this.openModal('error');
        });
    },
    getExcelClaimFile() {
      this.spinning = true;
      return filesAPIHandler.getFile(this.excel_claim_file, this.$router)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'oric_excel_claim_file.xlsx'); // Set the download attribute to the filename you want
          document.body.appendChild(link);
          link.click();
          this.spinning = false;
        })
        .catch((error) => {
          this.spinning = false;
          this.error_txt = 'File Error: ' + error;
          this.openModal('error');
        });
    },
    getExcelCancellationFile() {
      this.spinning = true;
      return filesAPIHandler.getFile(this.oric_excel_cancellation_filename, this.$router)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'oric_excel_cancellation_file.xlsx'); // Set the download attribute to the filename you want
          document.body.appendChild(link);
          link.click();
          this.spinning = false;
        })
        .catch((error) => {
          this.spinning = false;
          this.error_txt = 'File Error: ' + error;
          this.openModal('error');
        });
    },
    getExcelActivationFile() {
      this.spinning = true;
      return filesAPIHandler.getFile(this.oric_excel_activation_filename, this.$router)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'oric_excel_activation_file.xlsx'); // Set the download attribute to the filename you want
          document.body.appendChild(link);
          link.click();
          this.spinning = false;
        })
        .catch((error) => {
          this.spinning = false;
          this.error_txt = 'File Error: ' + error;
          this.openModal('error');
        });
    },
    getORICActivationReport() {
      this.spinning = true;
      if (!this.start_date) {
        this.error_txt = 'No start date!';
        this.openModal('error');
        return;
      }
      if (!this.end_date) {
        this.error_txt = 'No end date!';
        this.openModal('error');
        return;
      }
      this.activationRan = false;
      const start_date = moment(this.start_date).format('MM-DD-YYYY 00:00:00');
      const end_date = moment(this.end_date).format('MM-DD-YYYY 23:59:59');
      return reportsAPIHandler.getORICActivationReport(start_date, end_date, this.$router)
        .then((response) => {
          this.filenames = response.filenames;
          /*
          this.oric_csv_activation_filename = response.activation_csv_filepath;
          this.oric_excel_activation_filename = response.activation_excel_path;
          this.oric_csv_cancellation_filename = response.cancellation_csv_path;
          this.oric_excel_cancellation_filename = response.cancellation_excel_path;
          this.claim_headers_csv_path = response.claim_headers_csv_path;
          this.claim_details_csv_path = response.claim_details_csv_path;
          this.excel_claim_file = response.claim_excel_filename;
           */
          this.activationRan = true;
          this.spinning = false;
        })
        .catch((error) => {
          this.spinning = false;
          this.error_txt = 'error: ' + error;
          this.openModal('error');
        });
    },
    customFormatter(date) {
      return moment(date).utc().format('YYYY-MM-DD');
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
  }
}
</script>

<style scoped>

</style>
