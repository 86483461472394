import { CommonAPIService } from './CommonHandler';

export class ProductsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  get(router) {
    const url = `/api/pinnacle_products`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  getAllProductSets(router) {
    const url = `/api/pinnacle_products/all`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  create(product, router) {
    const url = `/api/pinnacle_products`;
    return this.CommonAPIService.postCall(url, product, router);
  }
  delete(id, router) {
    const url = `/api/pinnacle_products/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }
  update(id, product, router) {
    const url = `/api/pinnacle_products/${id}`;
    return this.CommonAPIService.putCall(url, product, router);
  }
  createProductSet(productSet, router) {
    const url = `/api/pinnacle_products/pinnacle_products`;
    return this.CommonAPIService.postCall(url, productSet, router);
  }
  docusignW9(info, router) {
    const url = `/api/pinnacle_products/docusign_w9`;
    return this.CommonAPIService.postCall(url, info, router);
  }
  docusignACH(info, router) {
    const url = `/api/pinnacle_products/docusign_ach`;
    return this.CommonAPIService.postCall(url, info, router);
  }
  docusignOnboard(info, router) {
    const url = `/api/pinnacle_products/docusign_dealerOnboard`;
    return this.CommonAPIService.postCall(url, info, router);
  }
}

export default ProductsAPIService;
