<template>
<div>
  <card v-if="spinning">
    <div class="row">
      <div class="col-md-12 text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>
  </card>
  <card>
    <h2>User Scopes Management</h2>
    <!-- Search Box -->
    <el-input
      v-model="search"
      placeholder="Search scopes..."
      clearable
    />
    <el-button @click="openModal('scope')">Add New Scope</el-button>

    <el-table :data="filteredScopes" border style="width: 100%">
      <el-table-column label="Assign to Auth Group">
        <template slot-scope="scope">
          <el-button @click="selectAuthgroup(scope.row)" icon="el-icon-plus" type="primary" circle></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Scope Name">
        <template slot-scope="scope">
          <el-input v-model="scope.row.name" />
        </template>
      </el-table-column>
      <el-table-column prop="description" label="Description">
        <template slot-scope="scope">
          <el-input v-model="scope.row.description" />
        </template>
      </el-table-column>
      <el-table-column label="Actions">
        <template slot-scope="scope">
          <el-button @click="updateScope(scope.row)" icon="el-icon-check" circle></el-button>
          <el-button @click="deleteScope(scope.row)" icon="el-icon-delete" type="danger" circle></el-button>
        </template>
      </el-table-column>
    </el-table>
  </card>
  <el-dialog
    center
    title="Confirm Deletion of Scope"
    :visible.sync="modals.basic">
    <span>Are you sure you want to delete the scope: {{ delete_scope_name }}?</span>
    <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal('basic')">Cancel</button>&nbsp;
              <button class="btn btn-success" @click="deleteScope()">Confirm</button>
            </span>
  </el-dialog>
  <el-dialog
    center
    title="Success"
    :visible.sync="modals.success">
    <div class="text-center">
      <span>Success: {{ success_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
<button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
</span>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
    </div>
  </el-dialog>
  <!-- Assign Dialog -->
  <el-dialog title="Assign Scopes to Auth Groups" :visible.sync="modals.assign" width="70%">
    <div style="display: flex;">
      <!-- Auth Groups List -->
      <div style="flex: 1;">
        <h3>Select an Auth Group:</h3>
        <el-select v-model="selected_authgroup_id" placeholder="Select an Auth Group">
          <el-option
            v-for="group in auth_groups"
            :key="group.id"
            :label="group.name"
            :value="group.id">
          </el-option>
        </el-select>
      </div>

      <!-- Scopes List for Assignment -->
      <div style="flex: 2; margin-left: 20px;">
        <h3>Assign Scopes:</h3>
        <el-checkbox-group v-model="selected_scope_ids">
          <el-checkbox
            v-for="scope in scopes"
            :key="scope.id"
            :label="scope.id">
            {{ scope.name }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="saveScopes">Assign</el-button>
      <el-button @click="closeModal('assign')">Close</el-button>
    </span>
  </el-dialog>
  <el-dialog title="Add New Scope" :visible.sync="modals.scope">
    <el-form ref="addScopeForm">
      <el-form-item label="Scope Name">
        <el-input v-model="newScope.name"></el-input>
      </el-form-item>
      <el-form-item label="Description">
        <el-input v-model="newScope.description"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="closeModal('scope')">Cancel</el-button>
        <el-button type="primary" @click="addScope">Add</el-button>
      </span>
  </el-dialog>

  <!-- Scopes Table -->
  <el-table :data="filteredScopes" border style="width: 100%">
    <!-- ... the rest of your table as before ... -->
  </el-table>
</div>
</template>

<script>
import {Option, Select, Dialog, Button, Table, TableColumn, Form, FormItem, Checkbox, CheckboxGroup } from 'element-ui';
import { ScopesAPIService } from 'src/servicehandlers/ScopesAPIService';
import { AuthGroupAPIService } from 'src/servicehandlers/AuthGroupAPIService';
const scopesAPIService = new ScopesAPIService();
const authGroupAPIService = new AuthGroupAPIService();

export default {
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  name: "AuthGroupsScopesAdmin",
  data() {
    return {
      newScope: {
        name: '',
        description: '',
      },
      search: '',
      searchTerm: null,
      spinning: false,
      scope_name: null,
      auth_groups: [],
      scope_description: null,
      delete_scope_id: null,
      delete_scope_name: null,
      selected_authgroup_id: null,
      last_selected_item: null,
      selected_scope_ids: [],
      modals: {
        error: false,
        success: false,
        basic: false,
        scope: false,
        assign: false,
      },
      scopes: [],
      success_txt: '',
      error_txt: null,
    };
  },
  computed: {
    filteredScopes() {
      if (!this.search) {
        return this.scopes;
      }
      const searchLowerCase = this.search.toLowerCase();
      return this.scopes.filter(
        s => s.name.toLowerCase().includes(searchLowerCase) ||
          s.description.toLowerCase().includes(searchLowerCase)
      );
    }
  },
  watch: {
    selected_authgroup_id(newValue, oldValue) {
      if (newValue !== oldValue) {
        const selectedRow = this.auth_groups.find(group => group.id === newValue);
        if (selectedRow) {
          this.selectAuthgroup(selectedRow);
        }
      }
    }
  },
  created() {
    this.getAllScopes();
    this.getAllAuthgroups();
  },
  methods: {
    created() {
      // By default, the filtered scopes are all the scopes
      this.filteredScopes = this.scopes;
    },
    saveScopes() {
      this.spinning = true;
      if (!this.selected_authgroup_id) {
        this.spinning = false;
        this.error_txt = 'No authgroup was selected!';
        this.openModal('error');
        return;
      }
      if (this.selected_scope_ids.length <= 0) {
        this.spinning = false;
        this.error_txt = 'No scopes were selected!';
        this.openModal('error');
        return;
      }

      return scopesAPIService.addScopesToAuthgroup(this.selected_authgroup_id, this.selected_scope_ids, this.$router)
      .then(() => {
        this.clear();
        this.getAllScopes();
        this.getAllAuthgroups();
        this.spinning = false;
        this.success_txt = 'Successfully added scopes to authgroup!';
        this.openModal('success');
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'An error occurred while adding scopes to groups: ' + error;
        this.openModal('error');
      });
    },
    clear() {
      this.selected_authgroup_id = null;
      this.selected_scope_ids = [];
    },
    updateScope(row) {
      this.spinning = true;
      return scopesAPIService.updateScope(row.id, row, this.$router)
      .then(() => {
        this.handleSuccess('Successfully updated scope');
      })
      .catch((error) => {
        this.handleSuccess('failed to update scope!: ' + error);
      });
    },
    async selectAuthgroup(row = null) {
      if (row) {
        this.selected_authgroup_id = row.id;
      }

      if (!this.selected_authgroup_id) {
        return;
      }

      this.spinning = true;

      try {
        const selectedAuthgroup = await authGroupAPIService.getAuthGroupsWithScope(this.selected_authgroup_id, this.$router);

        this.selected_scope_ids = selectedAuthgroup.scopes.map(scope => {
          scope.text = scope.name;
          scope.value = scope.id;
          return scope.id;
        });

        this.openModal('assign');
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
        this.error_txt = 'Error getting scopes for auth group: ' + error;
        this.openModal('error');
      }
    },

    getAllAuthgroups() {
      this.spinning = true;
      return authGroupAPIService.getAuthGroups(this.$router)
      .then((agps) => {
        this.auth_groups = agps;
        for (const group of this.auth_groups) {
          group.text = group.name;
          group.value = group.id;
        }
        this.spinning = false;
      })
      .catch((error) => {
        this.error_txt = error;
        this.spinning = false;
        this.openModal('error');
      });
    },
    onSelect (items, lastSelectItem) {
      this.selected_scope_ids = items
      this.lastSelectItem = lastSelectItem
    },
    confirmDelete(scope_id, scope_name,) {
      this.delete_scope_id = scope_id;
      this.delete_scope_name = scope_name;
      this.openModal('basic');
    },
    deleteScope(row) {
      this.closeModal('basic');
      this.spinning = true;
      if (!row.id) {
        this.spinning = false;
        this.error_txt = 'No scope was selected';
        this.openModal('error');
        return;
      }

      return scopesAPIService.deleteScope(row.id, this.$router)
      .then(() => {
        this.getAllScopes();
        this.getAllAuthgroups();
        this.spinning = false;
        this.success_txt = 'Successfully deleted scope!';
        this.openModal('success');
        this.delete_scope_id = null;
        this.delete_scope_name = null;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Scope deletion failed: ' + error;
        this.openModal('error');
      });
    },
    addScope() {
      this.spinning = true;
      if (!this.newScope.name) {
        this.spinning = false;
        this.error_txt = 'No scope name set';
        this.openModal('error');
        return;
      }
      if (!this.newScope.description) {
        this.spinning = false;
        this.error_txt = 'No scope description set';
        this.openModal('error');
        return;
      }
      this.closeModal('scope');

      return scopesAPIService.createScope(this.newScope, this.$router)
      .then(() => {
        this.getAllScopes();
        this.getAllAuthgroups();
        this.spinning = false;
        this.success_txt = 'Successfully created scope!';
        this.openModal('success');
        this.newScope.name = '';
        this.newScope.description = '';
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Scope creation failed: ' + JSON.stringify(error);
        this.openModal('error');
      });
    },

    getAllScopes() {
      this.spinning = true;
      return scopesAPIService.getScopes(this.$router)
      .then((response) => {
        this.spinning = false;
        this.scopes = response;
        for (const scope of this.scopes) {
          scope.text = scope.name;
          scope.value = scope.id;
        }
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error getting scopes!: ' + error;
        this.openModal('error');
      });
    },

    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true;
    },
    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_txt = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 1500); // Hide after 1.5 secs
    },
  }
}
</script>

<style scoped>

</style>
