import { CommonAPIService } from './CommonHandler';

export class DealerAPIHandler {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  get(router) {
    const url = `/api/dealers`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  create(dealer, router) {
    const url = `/api/dealers`;
    return this.CommonAPIService.postCall(url, dealer, router);
  }
  delete(id, router) {
    const url = `/api/dealers/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }
  update(id, dealer, router) {
    const url = `/api/dealers/${id}`;
    return this.CommonAPIService.putCall(url, dealer, router);
  }
  insertDealer(dealer, router) {
    const url = `/api/dealers/insert`;
    return this.CommonAPIService.postCall(url, dealer, router);
  }
}

export default DealerAPIHandler;
