import { CommonAPIService } from './CommonHandler';

export class FilesAPIHandler {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  uploadFiles(dealer_name, formdata, router) {
    const url = `/api/files/${dealer_name}`;
    return this.CommonAPIService.newFileCall(url, formdata, router);
  }
  isFileReady(filename, router) {
    const url = `/api/files/${filename}/ready`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getFile(filename, router) {
    const url = `/api/files/${filename}/file`;
    return this.CommonAPIService.fileCall(url, '', router);
  }

  getDealerOnboard(router) {
    const url = `/api/files/dealerOnboard`;
    return this.CommonAPIService.fileCall(url, '', router);
  }

  getWarrantyClaimGuide(router) {
    const url = `/api/files/warrantyClaimGuide`;
    return this.CommonAPIService.fileCall(url, '', router);
  }

  getContractProcessingGuide(router) {
    const url = `/api/files/contractProcessingGuide`;
    return this.CommonAPIService.fileCall(url, '', router);
  }

  getContractCancelingGuide(router) {
    const url = `/api/files/contractCancelingGuide`;
    return this.CommonAPIService.fileCall(url, '', router);
  }

  getDealerFiles(router, dealer_name) {
    const url = `/api/files/${dealer_name}/getDealerFiles`
    return this.CommonAPIService.getCall(url, '', router);
  }

  getDealerFile(router, dealer_name, filename) {
    const url = `/api/files/${filename}/${dealer_name}/getDealerFile`;
    return this.CommonAPIService.fileCall(url, '', router);
  }
}

export default FilesAPIHandler;
