<template>
  <div>
    <card v-if="spinning">
      <b-row class="text-center">
        <b-col>
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </b-col>
      </b-row>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-4 text-center ">
          <datepicker
            :bootstrap-styling="true"
            :format="customFormatter"
            placeholder="Start Date"
            v-model="start_date"
            input-class="fixcursor"
          >
          </datepicker>
        </div>
        <div class="col-sm-4 text-center ">
          <datepicker
            :bootstrap-styling="true"
            :format="customFormatter"
            placeholder="End Date"
            v-model="end_date"
            input-class="fixcursor"
          >
          </datepicker>
        </div>
      </div>
      <div class="row col-sm-8" style="padding-top: 1em!important; padding-bottom:1em;">
        <div class="col-sm-4 epad">
          <select style="height: 2.0em;font-size:1.2em;" name="Code" id="rtype" v-model="selected_type">
            <option v-for="rtype of report_types" v-bind:key="rtype.id" :value="rtype.id">{{rtype.name}}</option>
          </select>
        </div>
        <div class="col-sm-8 epad">
          <select style="height: 2.0em;font-size:1.2em;" name="Prefix" id="prefix" v-model="selected_prefix">
            <option v-for="prefix of prefixes" v-bind:key="prefix.name" :value="prefix.name">{{prefix.name}}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <br/>
        <div class="col-sm-8 epad">
          <button class="btn btn-primary" @click="getRollingReport">Get Rolling Report</button>
        </div>
      </div>
    </card>
    <card v-if="is_ran">
      <div class="row">
        <div class="col-sm-6 text-left">
          <download-excel
            class="btn btn-primary"
            :data="reportData"
            :fields="json_fields"
            worksheet="Rolling Report"
            name="Rolling Report.xls">

            Download Excel
          </download-excel>
        </div>
      </div>
      <div class="row table-header">
        <div class="col-sm-1">{{monthType}}</div>
        <div class="col-sm-3">Seller Name</div>
        <div class="col-sm-1">Seller #</div>
        <div class="col-sm-1">Agent #</div>
        <div class="col-sm-3">Agent Name</div>
        <div class="col-sm-1">Count</div>
      </div>
      <div 
        class="row"
        v-for="(reportItem, index) in reportData"
        v-bind:key="reportItem.id"
        :class="{'table-light': index %2}"
      >
        <div class="col-sm-1">{{ monthName(reportItem['Month']) }} {{ reportItem['Year'] }}</div>
        <div class="col-sm-3">{{ reportItem['Seller Name'] }}</div>
        <div class="col-sm-1">{{ reportItem['Seller #'] }}</div>
        <div class="col-sm-1">{{ reportItem['Agent #'] }}</div>
        <div class="col-sm-3">{{ reportItem['Agent Name'] }}</div>
        <div class="col-sm-1">{{ reportItem['Count'] }}</div>
      </div>
      <div class="row">
        <div class="col-sm-8"></div>
        <div class="col-sm-1">Total:</div>
        <div class="col-sm-1">{{ totalCount }}</div>
      </div>
    </card>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <span>{{success_txt}}</span>
      <span slot="footer" class="dialog-footer">
              <button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
            </span>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import Datepicker from 'vue-moment-datepicker';
import ReportsAPIHandler from "src/servicehandlers/ReportsAPIHandler";
const reportsAPIHandler = new ReportsAPIHandler();
import { Dialog } from 'element-ui';

const date = new Date();

export default {
  components: {
    Datepicker,
    [Dialog.name]: Dialog,
  },
  name: "PCMIRollingReport",
  data() {
    return {
      spinning: false,
      reportData: [],
      is_ran: false,
      totalCount: 0,
      start_date: new Date(date.getFullYear(), date.getMonth(), 1),
      end_date: new Date(date.getFullYear(), date.getMonth() + 1, 0),
      success_txt: null,
      error_txt: null,
      selected_type: 2,
      monthType: 'Activated',
      selected_prefix: null,
      prefixes: [{}],
      months: [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ],
      report_types: [{id:1, name: 'Pending Contracts'}, {id:2, name: 'Activated Contracts'}],
      json_fields: {
        'Seller Name': 'Seller Name',
        'Seller #': 'Seller #',
        'Agent Name': 'Agent Name',
        'Agent #': 'Agent #',
        'Count': 'Count',
      },
      modals: {
        basic: false,
        error: false,
        success: false,
        files: false,
      },
    }
  },
  beforeMount() {
    this.getPrefixList();
  },
  methods: {
    monthName(numeric) {
      return this.months[numeric - 1];
    },
    getPrefixList(){

      const parameters = '';
      reportsAPIHandler.getPrefixList(parameters, this.$route)
      .then((resp) => {

        for (const item of resp.all_prefixes) {
          this.prefixes.push( {name: item.name} );
        }

      })
      .catch((error) => {

      });
    },
    getRollingReport() {

      if (!this.start_date) {
        this.error_txt = 'No start date set';
        this.openModal('error')
        return;
      }

      if (!this.end_date) {
        this.error_txt = 'No end date set';
        this.openModal('error')
        return;
      }

      const types = ['', 'Inserted', 'Activated'];
      this.monthType = types[this.selected_type];

      this.is_ran = false;
      this.spinning = true;
      const start_date = moment(this.start_date).format('YYYY-MM-DD');
      const end_date = moment(this.end_date).format('YYYY-MM-DD');
      const rtype = this.selected_type;
      const prefix = this.selected_prefix;
      const parameters = { params:{ rtype, prefix}};

      reportsAPIHandler.getRollingReport(start_date, end_date, parameters, this.$route)
      .then((resp) => {

        this.reportData = [];
        this.totalCount = 0;
        let count = 1;

        for (const item of resp) {
          item.id = count;
          this.totalCount += parseInt(item['Count']);
          this.reportData.push(item);
          count++;
        }

        this.is_ran = true;
        this.spinning = false;

      })
      .catch((error) => {

        this.spinning = false;
        this.error_txt = 'error: ' + error;
        this.openModal('error');

      });
    },
    customFormatter(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
  }
}
</script>

<style>
  .epad {padding-top: .5em; padding-bottom: .5em;}
  .fixcursor {cursor:pointer!important;}
  .table-light {background-color: #EEE;}
  .table-header {background-color: #AAA; font-weight:bold;}
</style>
