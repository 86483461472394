import { CommonAPIService } from './CommonHandler';

export class ReportsAPIHandler {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getORICActivationReport(start_date, end_date, router) {
    const url = `/api/reports/${start_date}/${end_date}/oric`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getRollingReport(date1, date2, parameters, router) {
    const url = `/api/reports/${date1}/${date2}/rolling`;
    return this.CommonAPIService.getCall(url, parameters, router);
  }

  getPrefixList(parameters, router) {
    const url = `/api/reports/prefixes`;
    return this.CommonAPIService.getCall(url, parameters, router);
  }

  getDNAReport(start_date, end_date, router) {
    const url = `/api/reports/${start_date}/${end_date}/dan`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  getQuestReport(start_date, end_date, router) {
    const url = `/api/reports/${start_date}/${end_date}/quest`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  getNovaReport(start_date, end_date, router) {
    const url = `/api/reports/${start_date}/${end_date}/nova`;
    return this.CommonAPIService.getCall(url, '', router);
  }
}

export default ReportsAPIHandler;
