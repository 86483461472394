<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-4">
          <select name="Code" id="code" style="width: 100%" v-model="selected_code" @change="selectCode">
            <option v-for="code of codes" v-bind:key="code.id" :value="code.id">{{code.name}}</option>
          </select>
        </div>
        <div class="col-sm-4">
          <select name="Code" id="code" style="width: 100%" v-model="selected_type">
            <option v-for="type of types" v-bind:key="type.id" :value="type.id">{{type.name}}</option>
          </select>
        </div>
        <div class="col-sm-4 text-center">
          <button class="btn btn-primary" @click="saveChanges">Save</button>
        </div>
      </div>
    </card>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <span>{{ success_txt }}</span>
      <span slot="footer" class="dialog-footer">
              <button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
            </span>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import CoverageCodesAPIService from "../../../../servicehandlers/CoverageCodesAPIService";
const coverageCodesAPIHandler = new CoverageCodesAPIService();
import { Dialog } from 'element-ui';
export default {
  name: "CoverageCodes",
  components: {
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      types: [
        {
          name: 'VSC',
          id: 'vsc'
        },
        {
          name: 'GAP',
          id: 'gap'
        },
        {
          name: 'Appearance Protection',
          id: 'appearance_protection'
        },
        {
          name: 'Other',
          id: 'other'
        },
        {
          name: 'Unknown',
          id: 'unknown'
        },
      ],
      codes: [],
      selected_code: null,
      selected_type: null,
      spinning: false,
      success_txt: null,
      error_txt: null,
      modals: {
        error: false,
        success:false,
      }
    }
  },
  mounted(){
    this.getCoverageCodes()
  },
  methods: {
    getCoverageCodes(){
      this.spinning = true
      return coverageCodesAPIHandler.get(this.$router)
        .then((response) => {
          this.codes = response
          this.spinning = false
        })
        .catch((e) => {
          this.spinning = false
          this.error_txt = "Failed to get Coverage Codes: " + e
          this.openModal('error')
        })
    },
    selectCode(){
      if(this.selected_code){
        for (let code of this.codes){
          if (code.id === this.selected_code){
            this.selected_type = code.type
            break
          }
        }
      }
    },
    async saveChanges(){
      this.spinning = true
      let temp = {type: this.selected_type}
      return await coverageCodesAPIHandler.update(this.selected_code, temp, this.$router)
        .then(() => {
          this.spinning = false
          this.success_txt = "Succesfully updated database."
          this.openModal('success')
        }).catch((e) => {
          this.spinning = false
          this.error_txt = "Error Updating database." + e
          this.openModal('error')
        })
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
  }
}
</script>

<style scoped>

</style>
