import { CommonAPIService } from 'src/servicehandlers/CommonHandler';

export class UserAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getUserList(router) {
    const url = '/api/users';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getEmailAdminList(router) {
    const url = '/api/users/adminlist';
    return this.CommonAPIService.getCall(url, '', router);
  }

  createUser(parms, router) {
    const url = '/api/users';
    return this.CommonAPIService.postCall(url, parms, router);
  }
  deleteUser(id, router) {
    const url = `/api/users/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }

  getUserById(user_id, router) {
    const url = `/api/users/${user_id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getSingleUser(user_id, router) {
    const url = `/api/users/${user_id}/single`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  updateUser(user_id, parms, router) {
    const url = `/api/users/${user_id}`;
    return this.CommonAPIService.putCall(url, parms, router);
  }

  getUserWithAuthgroups(user_id, router) {
    const url = `/api/users/${user_id}/authgroups`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  /************* RIGHTS CALLS *************/
  getUsersByCompanyID(router) {
    const url = `/api/users/companies`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  getUsersByCompanyIDHP(company_id, router) {
    const url = `/api/users/${company_id}/companies`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getUsersScopes(router) {
    const url = `/api/users/scopes`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  getUsersScopesHP(company_id, router) {
    const url = `/api/users/${company_id}/scopes`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getUserHostRights(user_id, router) {
    const url = `/api/users/${user_id}/host`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  linkUserToHosts(user_id, host_ids, router) {
    const url = `/api/users/${user_id}/${host_ids}/link_hosts`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  linkUserToScopes(user_id, scope_ids, router) {
    const url = `/api/users/${user_id}/${scope_ids}/link_scopes`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  linkUserToCommandLookups(user_id, command_lookup_ids, router) {
    const url = `/api/users/${user_id}/${command_lookup_ids}/link_command_lookups`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getUserToScopes(user_id, router) {
    const url = `/api/users/${user_id}/linked_scopes`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  getUserToHosts(user_id, router) {
    const url = `/api/users/${user_id}/linked_hosts`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  getUserToCommandLookups(user_id, router) {
    const url = `/api/users/${user_id}/linked_command_lookups`;
    return this.CommonAPIService.getCall(url, '', router);
  }
}
export default UserAPIService;
