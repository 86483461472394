import { CommonAPIService } from './CommonHandler';

export class SessionAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  get(router) {
    const url = `/api/sessions`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  create(session, router) {
    const url = `/api/sessions`;
    return this.CommonAPIService.postCall(url, session, router);
  }
  delete(id, router) {
    const url = `/api/sessions/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }
  update(id, session, router) {
    const url = `/api/sessions/${id}`;
    return this.CommonAPIService.putCall(url, session, router);
  }
}

export default SessionAPIService;
