<template>
  <ul class="nav nav-mobile-menu">
    <drop-down>
      <template slot="title">
        <i class="nc-icon nc-bullet-list-67"></i>
      </template>
      <a href="#" class="dropdown-item text-danger">
        <i class="nc-icon nc-button-power"></i> Log out
      </a>
    </drop-down>
  </ul>
</template>
<script>
  import DropDown from 'src/components/Dropdown.vue'

  export default {
    components: {
      DropDown
    }
  }
</script>
<style>
</style>
