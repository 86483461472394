<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-4 text-center">
          <datepicker
            class="editable editable-click"
            :bootstrap-styling="true"
            :format="customFormatter"
            placeholder="Start Date"
            v-model="start_date">
          </datepicker>
        </div>
        <div class="col-sm-4 text-center">
          <datepicker
            class="editable editable-click"
            :bootstrap-styling="true"
            :format="customFormatter"
            placeholder="End Date"
            v-model="end_date">
          </datepicker>
        </div>
        <div class="col-sm-4">
          <button class="btn-primary" @click="getDNAReport">Generate DNA Report</button>
        </div>
      </div>
    </card>
    <card v-if="activationRan">
      <div class="row">
        <div class="col-sm-6 text-center'">
          <button class="btn btn-primary" @click="getCSVActivationFile">Get DAN CSV Activation File</button>
        </div>
        <div class="col-sm-6 text-center'">
          <button class="btn btn-primary" @click="getExcelActivationFile">Get DAN Excel Activation File</button>
        </div>
      </div>
    </card>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <span>{{ success_txt }}</span>
      <span slot="footer" class="dialog-footer">
              <button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
            </span>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Datepicker from 'vue-moment-datepicker';
import moment from 'moment';
import ReportsAPIHandler from "../../../../servicehandlers/ReportsAPIHandler";
import FilesAPIHandler from "../../../../servicehandlers/FilesAPIHandler";
const reportsAPIHandler = new ReportsAPIHandler();
const filesAPIHandler = new FilesAPIHandler();
import { Dialog } from 'element-ui';
export default {
  name: "DNAReport",
  components: {
    [Dialog.name]: Dialog,
    Datepicker
  },
  data() {
    return {
      excel_claim_file: null,
      dna_csv_activation_filename: null,
      dna_excel_activation_filename: null,
      spinning: false,
      isRan: false,
      activationRan: false,
      start_date: null,
      end_date: null,
      success_txt: null,
      error_txt: null,
      modals: {
        error: false,
        success:false,
      }
    }
  },
  methods: {
    getCSVActivationFile() {
      this.spinning = true;
      return filesAPIHandler.getFile(this.dna_csv_activation_filename, this.$router)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'dna_csv_file.csv'); // Set the download attribute to the filename you want
        document.body.appendChild(link);
        link.click();
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'File Error: ' + error;
        this.openModal('error');
      });
    },
    getExcelActivationFile() {
      this.spinning = true;
      return filesAPIHandler.getFile(this.dna_excel_activation_filename, this.$router)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'dna_excel_file.xlsx'); // Set the download attribute to the filename you want
        document.body.appendChild(link);
        link.click();
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'File Error: ' + error;
        this.openModal('error');
      });
    },
    getDNAReport() {
      this.spinning = true;
      if (!this.start_date) {
        this.error_txt = 'No start date!';
        this.openModal('error');
        return;
      }
      if (!this.end_date) {
        this.error_txt = 'No end date!';
        this.openModal('error');
        return;
      }
      this.activationRan = false;
      const start_date = moment(this.start_date).format('MM-DD-YYYY 00:00:00');
      const end_date = moment(this.end_date).format('MM-DD-YYYY 23:59:59');
      return reportsAPIHandler.getDNAReport(start_date, end_date, this.$router)
      .then((response) => {
        this.dna_csv_activation_filename = response.dna_csv_filepath;
        this.dna_excel_activation_filename = response.dna_excel_filepath;
        this.activationRan = true;
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'error: ' + error;
        this.openModal('error');
      });
    },
    customFormatter(date) {
      return moment(date).utc().format('YYYY-MM-DD');
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
  }
}
</script>

<style scoped>

</style>
