<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-4 text-center">
          <datepicker
            class="editable editable-click"
            :bootstrap-styling="true"
            :format="customFormatter"
            placeholder="Start Date"
            v-model="start_date">
          </datepicker>
        </div>
        <div class="col-sm-4 text-center">
          <datepicker
            class="editable editable-click"
            :bootstrap-styling="true"
            :format="customFormatter"
            placeholder="End Date"
            v-model="end_date">
          </datepicker>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4"></div>
        <div class="col-sm-4">
          <button class="btn-primary" @click="getQuestReport">Generate Quest Reports</button>
        </div>
        <div class="col-sm-4"></div>
      </div>
    </card>
    <card v-if="activationRan">
      <div class="row">
        <div class="col-sm-2 text-center'">
          <button class="btn btn-primary" @click="getPolicyCSVFile">Get Quest CSV Policy File</button>
        </div>
        <div class="col-sm-2 text-center'">
          <button class="btn btn-primary" @click="getPolicyExcelFile">Get Quest Excel Policy File</button>
        </div>
        <div class="col-sm-2 text-center'">
          <button class="btn btn-primary" @click="getDriverCSVFile">Get Driver CSV Driver File</button>
        </div>
        <div class="col-sm-2 text-center'">
          <button class="btn btn-primary" @click="getDriverExcelFile">Get Driver Excel Driver File</button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 text-center'">
          <button class="btn btn-primary" @click="getVehicleCSVFile">Get Quest CSV Vehicle File</button>
        </div>
        <div class="col-sm-2 text-center'">
          <button class="btn btn-primary" @click="getVehicleExcelFile">Get Quest Excel Vehicle File</button>
        </div>
      </div>
    </card>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <span>{{ success_txt }}</span>
      <span slot="footer" class="dialog-footer">
              <button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
            </span>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Datepicker from 'vue-moment-datepicker';
import moment from 'moment';
import ReportsAPIHandler from "../../../../servicehandlers/ReportsAPIHandler";
import FilesAPIHandler from "../../../../servicehandlers/FilesAPIHandler";
const reportsAPIHandler = new ReportsAPIHandler();
const filesAPIHandler = new FilesAPIHandler();
import { Dialog } from 'element-ui';
export default {
  name: "QuestReport",
  components: {
    [Dialog.name]: Dialog,
    Datepicker
  },
  data() {
    return {
      excel_claim_file: null,
      policy_csv_filename: null,
      policy_excel_filename: null,
      vehicle_csv_filename: null,
      vehicle_excel_filename: null,
      agent_csv_filename: null,
      agent_excel_filename: null,
      driver_csv_filename: null,
      driver_excel_filename: null,
      spinning: false,
      isRan: false,
      activationRan: false,
      start_date: null,
      end_date: null,
      success_txt: null,
      error_txt: null,
      modals: {
        error: false,
        success:false,
      }
    }
  },
  methods: {
    getPolicyExcelFile() {
      const now = moment().format('YYYY-MM-DD');
      this.getCSVActivationFile(this.policy_excel_filename, `${now} ascent quest policy excel file.xlsx`);
    },
    getPolicyCSVFile() {
      const now = moment().format('YYYY-MM-DD');
      this.getCSVActivationFile(this.policy_csv_filename, `${now} ascent quest policy csv file.csv`);
    },
    getVehicleExcelFile() {
      const now = moment().format('YYYY-MM-DD');
      this.getCSVActivationFile(this.vehicle_excel_filename, `${now} ascent quest vehicle excel file.csv`);
    },
    getVehicleCSVFile() {
      const now = moment().format('YYYY-MM-DD');
      this.getCSVActivationFile(this.vehicle_csv_filename, `${now} ascent quest vehicle csv file.csv`);
    },
    getDriverExcelFile() {
      const now = moment().format('YYYY-MM-DD');
      this.getCSVActivationFile(this.driver_excel_filename, `${now} ascent quest driver excel file.csv`);
    },
    getDriverCSVFile() {
      const now = moment().format('YYYY-MM-DD');
      this.getCSVActivationFile(this.driver_csv_filename, `${now} ascent quest driver csv file.csv`);
    },
    getCSVActivationFile(filename, downloadname) {
      this.spinning = true;
      return filesAPIHandler.getFile(filename, this.$router)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', downloadname); // Set the download attribute to the filename you want
        document.body.appendChild(link);
        link.click();
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'File Error: ' + error;
        this.openModal('error');
      });
    },
    getExcelActivationFile() {
      this.spinning = true;
      return filesAPIHandler.getFile(this.policy_excel_filename, this.$router)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${now} ascent quest policy file.xlsx`); // Set the download attribute to the filename you want
        document.body.appendChild(link);
        link.click();
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'File Error: ' + error;
        this.openModal('error');
      });
    },
    getQuestReport() {
      if (!this.start_date) {
        this.error_txt = 'No start date!';
        this.openModal('error');
        return;
      }
      if (!this.end_date) {
        this.error_txt = 'No end date!';
        this.openModal('error');
        return;
      }
      this.spinning = true;
      this.activationRan = false;
      const start_date = moment(this.start_date).format('MM-DD-YYYY 00:00:00');
      const end_date = moment(this.end_date).format('MM-DD-YYYY 23:59:59');
      return reportsAPIHandler.getQuestReport(start_date, end_date, this.$router)
      .then((response) => {
        this.policy_csv_filename = response.data.policy_csv_filename;
        this.policy_excel_filename = response.data.policy_excel_filename;
        this.agent_csv_filename = response.data.agent_csv_filename;
        this.agent_excel_filename = response.data.agent_excel_filename;
        this.vehicle_csv_filename = response.data.vehicle_csv_filename;
        this.vehicle_excel_filename = response.data.vehicle_excel_filename;
        this.driver_csv_filename = response.data.driver_csv_filename;
        this.driver_excel_filename = response.data.driver_excel_filename;
        // now setup an interval to keep waiting for it to finish because this is a MASSIVE report
        this.timerId = setInterval(this.getFileStatus, 1000);
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'error: ' + error;
        this.openModal('error');
      });
    },
    async getFileStatus() {
      this.spinning = true;
      await filesAPIHandler.isFileReady(this.policy_csv_filename, this.$router)
      .then((resp) => {
        if (resp.success === true) {
          this.activationRan = true;
          this.shortName = resp.shortName;
          clearInterval(this.timerId);
          this.spinning = false;
        }
      })
      .catch((error) => {
        this.error_txt = 'File status call failed!: ' + error;
        this.openModal('error');
        clearInterval(this.timerId);
        this.spinning = false;
      });
    },
    customFormatter(date) {
      return moment(date).utc().format('YYYY-MM-DD');
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
  }
}
</script>

<style scoped>

</style>
