import { CommonAPIService } from './CommonHandler';

export class CoverageCodesAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  get(router) {
    const url = `/api/coverage_codes`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  delete(id, router) {
    const url = `/api/coverage_codes/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }
  update(id, type, router) {
    const url = `/api/coverage_codes/${id}`;
    return this.CommonAPIService.putCall(url, type, router);
  }
}

export default CoverageCodesAPIService;
