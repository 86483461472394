<template>
<div>
  <card v-if="spinning">
    <div class="row">
      <div class="col-md-12 text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>
  </card>
  <card>
    <!-- Page Header -->
    <div class="row header">
      <div class="col">
        <h1>Authentication Group Management</h1>
      </div>
    </div>

    <!-- Search Box -->
    <div class="row table-header">
      <div class="col">
        <el-input
          v-model="searchQuery"
          placeholder="Search by Name, Description, or Alias"
          clearable
          @clear="searchQuery = ''">
          <template #append>
            <el-button icon="el-icon-close" @click="searchQuery = ''"></el-button>
          </template>
        </el-input>
      </div>
    </div>
    <!-- Create Authentication Group Button -->
    <div class="row">
      <div class="col">
        <el-button type="primary" @click="openModal('create')">Create Authentication Group</el-button>
      </div>
    </div>

    <!-- Table for displaying Authentication Groups -->
    <el-table :data="filteredAuthGroups" style="width: 100%">
      <el-table-column
        label="Actions"
        width="180">
        <template slot-scope="scope">
          <el-button @click="openAssignDialog(scope.row)">Assign Users</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Name">
        <template #default="scope">
          <el-input v-model="scope.row.name"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="description" label="Description">
        <template #default="scope">
          <el-input v-model="scope.row.description"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="alias" label="Alias">
        <template #default="scope">
          <el-input v-model="scope.row.alias"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Actions">
        <template #default="scope">
          <el-button type="primary" icon="el-icon-edit" @click="updateGroup(scope.row)">Update</el-button>
          <el-button type="danger" icon="el-icon-delete" @click="deleteGroup(scope.row)">Delete</el-button>
        </template>
      </el-table-column>
    </el-table>
  </card>
  <el-dialog
    center
    title="Confirm Deletion of Auth Group"
    :visible.sync="modals.basic">
    <span>Are you sure you want to delete the auth group: {{ delete_authgroup_name }}?</span>
    <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal('basic')">Cancel</button>&nbsp;
              <button class="btn btn-success" @click="deleteAuthGroup">Confirm</button>
            </span>
  </el-dialog>
  <el-dialog
    center
    title="Success"
    :visible.sync="modals.success">
    <div class="text-center">
      <span>Success: {{ success_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
<button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
</span>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
    </div>
  </el-dialog>
  <!-- Dialog for creating a new Authentication Group -->
  <el-dialog
    title="Create Authentication Group"
    :visible.sync="modals.create"
    width="50%">

    <div class="form-group">
      <label>Name:</label>
      <el-input v-model="newGroup.name"></el-input>
    </div>

    <div class="form-group">
      <label>Description:</label>
      <el-input v-model="newGroup.description"></el-input>
    </div>

    <div class="form-group">
      <label>Alias:</label>
      <el-input v-model="newGroup.alias"></el-input>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal('create')">Cancel</el-button>
      <el-button type="primary" @click="createAuthGroup">Save</el-button>
    </span>
  </el-dialog>
  <el-dialog
    :title="`Assign Users to ${selectedAuthGroup ? selectedAuthGroup.name : ''}`"
    :visible.sync="modals.users"
    width="80%">

    <!-- Transfer Component for Users -->
    <el-transfer
      class="custom-transfer"
      v-model="selectedUsers"
      :data="transferData"
      :titles="['Available Users', 'Assigned Users']"
      filterable
      :filter-method="filterMethod"
      filter-placeholder="Search for a user">
    </el-transfer>

    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal('users')">Cancel</el-button>
      <el-button type="primary" @click="assignUsersToGroup">Save</el-button>
    </span>
  </el-dialog>

</div>
</template>

<script>
import {Transfer, Checkbox, Input, Dialog, Button, Table, TableColumn } from 'element-ui';
import { UserAPIService } from 'src/servicehandlers/UsersAPIService';
import { AuthGroupAPIService } from 'src/servicehandlers/AuthGroupAPIService';
const usersAPIService = new UserAPIService();
const authGroupAPIService = new AuthGroupAPIService();

export default {
  name: "UserAuthgroupsAdmin",
  components: {
    [Transfer.name]: Transfer,
    [Checkbox.name]: Checkbox,
    [Dialog.name]: Dialog,
    [Input.name]: Input,
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      searchQuery: "",
      selected_authgroups: [],
      stringItem: '',
      modals: {
        error: false,
        success: false,
        create: false,
        users: false,
      },
      newGroup: {
        name: '',
        description: '',
        alias: ''
      },
      filteredGroups: [],
      transferData: [],
      filteredUserOptions: [],
      filterUserOptions: '',
      searchUserTerm: null,
      users: [],
      authgroup_name: null,
      showUserDialog: false,
      selectedAuthGroup: null,
      selectedUsers: [],
      authGroups: [],
      delete_authgroup_id: null,
      delete_authgroup_name: null,
      lastSelectItem: null,
      selected_auth_group_ids: [],
      selected_user_id: null,
      selected_authgroup_id: null,
      results: false,
      result_txt: '',
      success_txt: '',
      error_txt: null,
      spinning: false,
    };
  },
  async created() {
    await this.getAllAuthGroups();
    await this.getAllUsers();
    this.filteredGroups = this.authGroups;

    // Set transferData to represent all users
    this.transferData = this.users.map(user => ({
      key: user.id,
      label: `${user.first_name} ${user.last_name}`,
    }));
  },
  computed: {
    filteredAuthGroups() {
      return this.authGroups.filter(group =>
        (group.name ? group.name.toLowerCase().includes(this.searchQuery.toLowerCase()) : false) ||
        (group.description ? group.description.toLowerCase().includes(this.searchQuery.toLowerCase()) : false) ||
        (group.alias ? group.alias.toLowerCase().includes(this.searchQuery.toLowerCase()) : false)
      );
    }
  },
  mounted() {

  },
  methods: {
    filterMethod(query, item) {
      return item.label.toLowerCase().includes(query.toLowerCase());
    },
    async openAssignDialog(authGroup) {
      this.selectedAuthGroup = authGroup;

      // Reset selectedUsers
      this.selectedUsers = [];

      try {
        let allAuthgroups = await authGroupAPIService.getAuthGroupsWithUsers(authGroup.id, this.$router);

        // Populate selectedUsers with the IDs of the users for the current authGroup
        this.selectedUsers = allAuthgroups.users.map(user => user.id);

        this.openModal('users');

      } catch (error) {
        this.handleError('Error: could not assign or get groups with users!' + error);
      }
    },
    assignUsersToGroup() {
      this.selectedAuthGroup.users = [...this.selectedUsers];
      this.closeModal('users');

      const parms = {
        "user_ids": this.selectedUsers,
        "auth_group_id": this.selectedAuthGroup.id,
      };
      // now assign the users
      return authGroupAPIService.linkAuthgroupToUsers(parms, this.$router)
      .then(() => {
        this.handleSuccess('Successfully linked users to auth group!');
      })
      .catch((error) => {
        this.handleError('Failed to link users to auth group! ' + error);
      })
    },
    updateGroup(row) {
      return authGroupAPIService.updateAuthGroup(this.$router, row.id, row)
      .then(() => {
        this.handleSuccess('Successfully update authentication group!');
      })
      .catch((error) => {
        this.handleError('Error updating authentication group!: ' + error);
      });
    },
    deleteGroup(row) {
      // Implement logic to delete the group
      const index = this.authGroups.findIndex(group => group.id === row.id);
      if (index > -1) {
        this.authGroups.splice(index, 1);
      }
      return authGroupAPIService.deleteAuthGroup(this.$router, row.id)
      .then(() => {
        this.handleSuccess('Successfully deleted authentication group!');
      })
      .catch((error) => {
        this.handleError('Error deleting authentication group!: ' + error);
      });
    },
    handleRowClick(row, column, event) {
    },
    assignUsers(index, row) {
      // Implement your logic for assigning users here
    },
    handleSelectedAuthGroups(values) {
      this.selected_auth_group_ids = values;
    },
    preDeleteAuthgroup(group_id, group_name) {
      this.delete_authgroup_name = group_name;
      this.delete_authgroup_id = group_id;
      this.openModal('basic');
    },
    deleteAuthGroup() {
      this.closeModal('basic');
      this.spinning = true;
      if (!this.delete_authgroup_id) {
        this.error_txt = 'No Authgroup was selected!';
        this.openModal('error');
        this.spinning = false;
        return;
      }

      return authGroupAPIService.deleteAuthGroup(this.$router, this.delete_authgroup_id)
      .then(() => {
        this.getAllAuthGroups();
        this.success_txt = 'Successfully deleted authgroup!';
        this.openModal('success');
        this.selected_authgroup_id = null;
        this.spinning = false;
      })
      .catch((error) => {
        this.error_txt = 'Authgroup deletion failed: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    createAuthGroup() {
      this.spinning = true;
      if (!this.newGroup.name) {
        this.error_txt = 'No auth group name input!';
        this.openModal('error');
        this.spinning = false;
        return;
      }
      this.closeModal('create');

      return authGroupAPIService.addAuthGroup(this.$router, this.newGroup)
      .then((authgroup) => {
        this.getAllAuthGroups();
        this.handleSuccess('Successfully created group: ' + authgroup.name);
        this.newGroup = { name: '', description: '', alias: '' }; // Reset the object for future use.
        this.authgroup_name = null;
      })
      .catch((error) => {
        this.handleError('Error creating auth group: ' + error);
      });
    },
    save() {
      this.spinning = true;
      // save the IDS to the database for the user
      if (!this.selected_user_id) {
        this.error_txt = 'No user selected!';
        this.openModal('error');
        this.spinning = false;
        return;
      }
      if (this.selected_auth_group_ids.length <= 0) {
        this.error_txt = 'No auth groups selected!';
        this.openModal('error');
        this.spinning = false;
        return;
      }
      return authGroupAPIService.addUserToAuthGroups(this.selected_user_id, this.selected_auth_group_ids, this.$router)
      .then(() => {
        this.success_txt = 'Successfully updated groups for user';
        this.openModal('success');
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error saving users to groups: ' + error;
        this.openModal('error');
      });
    },
    clear() {
      this.selected_user_id = null;
      this.selected_auth_group_ids = [];
    },
    onSelect (items, lastSelectItem) {
      this.selected_auth_group_ids = items
      this.lastSelectItem = lastSelectItem
    },
    async getAllUsers() {
      this.spinning = true;
      return await usersAPIService.getUserList(this.$router)
      .then((allUsers) => {
        for (const user of allUsers) {
          user.text = user.last_name + ', ' + user.first_name;
          user.value = user.id;
        }
        this.users = allUsers;
        this.filteredUserOptions = allUsers;
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = error;
        this.openModal('error');
      });
    },

    selectUser() {
      // get the full user by ID with scopes, etc, from the DB
      this.spinning = true;
      return usersAPIService.getUserWithAuthgroups(this.selected_user_id, this.$router)
      .then((selectedUser) => {
        this.selected_auth_group_ids = [];
        this.spinning = false;
        const authgroups = selectedUser.auth_groups;
        for (const group of authgroups) {
          group.text = group.name;
          group.value = group.id;
          this.selected_auth_group_ids.push(group.id);
        }
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error getting user from DB: ' + error;
        this.openModal('error');
      });
    },

    async getAllAuthGroups() {
      this.spinning = true;
      return await authGroupAPIService.getAuthGroups(this.$router)
      .then((agps) => {
        this.authGroups = agps;
        for (const group of this.authGroups) {
          group.text = group.name;
          group.value = group.id;
        }
        this.spinning = false;
      })
      .catch((error) => {
        this.error_txt = error;
        this.spinning = false;
        this.openModal('error');
      });
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true;
    },
    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_txt = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    addNewGroup() {
      // Assuming 'id' is needed, auto-increment or generate the ID as needed.
      this.newGroup.id = Date.now(); // Simplistic ID generation; you might want to improve this.
      this.authGroups.push(this.newGroup);
      this.showDialog = false;
      this.newGroup = { name: '', description: '', alias: '' }; // Reset the object for future use.
    },
  }
}
</script>

<style scoped>
.header {
  padding: 20px;
  text-align: center;
  color: black;
}

.table-header {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.row {
  margin: 10px 0;
}
.form-group {
  margin-bottom: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.user-row {
  margin: 10px 0;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.custom-transfer .el-transfer-panel {
  width: 400px;  /* adjust width as needed */
  height: 400px; /* adjust height as needed */
}

.el-dialog__header {
  text-align: center;
  font-size: 24px;  /* adjust size as needed */
}
</style>
