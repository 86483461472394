<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-4 text-center">
          <datepicker
            class="editable editable-click"
            :bootstrap-styling="true"
            :format="customFormatter"
            placeholder="Start Date"
            v-model="start_date">
          </datepicker>
        </div>
        <div class="col-sm-4 text-center">
          <datepicker
            class="editable editable-click"
            :bootstrap-styling="true"
            :format="customFormatter"
            placeholder="End Date"
            v-model="end_date">
          </datepicker>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4"></div>
        <div class="col-sm-4">
          <button class="btn-primary" @click="getNovaReport">Get Nova Report</button>
        </div>
        <div class="col-sm-4"></div>
      </div>
    </card>
    <card v-if="activationRan">
      <div class="row">
        <div class="col-sm-2 text-center'">
        </div>
        <div class="col-sm-2 text-center'">
          <button class="btn btn-primary" @click="getNovaExcelFile">Get NOVA Excel File</button>
          <button class="btn btn-primary" @click="getNovaCancelExcelFile">Get NOVA Excel Cancellation File</button>
        </div>
        <div class="col-sm-2 text-center'">
        </div>
        <div class="col-sm-2 text-center'">
        </div>
      </div>
    </card>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <span>{{ success_txt }}</span>
      <span slot="footer" class="dialog-footer">
              <button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
            </span>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Datepicker from 'vue-moment-datepicker';
import moment from 'moment';
import ReportsAPIHandler from "../../../../servicehandlers/ReportsAPIHandler";
import FilesAPIHandler from "../../../../servicehandlers/FilesAPIHandler";
const reportsAPIHandler = new ReportsAPIHandler();
const filesAPIHandler = new FilesAPIHandler();
import { Dialog } from 'element-ui';
export default {
  name: "NOVAReport",
  components: {
    [Dialog.name]: Dialog,
    Datepicker
  },
  data() {
    return {
      nova_excel_filename: null,
      nova_cancel_excel_filename: null,
      spinning: false,
      isRan: false,
      activationRan: false,
      start_date: null,
      end_date: null,
      success_txt: null,
      error_txt: null,
      modals: {
        error: false,
        success:false,
      }
    }
  },
  methods: {
    getNovaCancelExcelFile() {
      const now = moment().format('YYYY-MM-DD');
      this.getExcelCancellationFile(this.nova_cancel_excel_filename, `${now} NOVA Cancellation File.xlsx`);
    },
    getNovaExcelFile() {
      const now = moment().format('YYYY-MM-DD');
      this.getExcelActivationFile(this.nova_excel_filename, `${now} NOVA File.xlsx`);
    },
    getExcelCancellationFile() {
      this.spinning = true;
      const now = moment().format('YYYY_MM_DD');
      return filesAPIHandler.getFile(this.nova_cancel_excel_filename, this.$router)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${now} NOVA Cancellation File.xlsx`); // Set the download attribute to the filename you want
        document.body.appendChild(link);
        link.click();
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'File Error: ' + error;
        this.openModal('error');
      });
    },
    getExcelActivationFile() {
      this.spinning = true;
      const now = moment().format('YYYY_MM_DD');
      return filesAPIHandler.getFile(this.nova_excel_filename, this.$router)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${now} NOVA File.xlsx`); // Set the download attribute to the filename you want
        document.body.appendChild(link);
        link.click();
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'File Error: ' + error;
        this.openModal('error');
      });
    },
    getNovaReport() {
      if (!this.start_date) {
        this.error_txt = 'No start date!';
        this.openModal('error');
        return;
      }
      if (!this.end_date) {
        this.error_txt = 'No end date!';
        this.openModal('error');
        return;
      }
      this.spinning = true;
      this.activationRan = false;
      const start_date = moment(this.start_date).format('MM-DD-YYYY 00:00:00');
      const end_date = moment(this.end_date).format('MM-DD-YYYY 23:59:59');
      return reportsAPIHandler.getNovaReport(start_date, end_date, this.$router)
      .then((response) => {
        this.nova_excel_filename = response.nova_contracts_excel;
        this.nova_cancel_excel_filename = response.nova_cancel_contracts_excel;
        // now set up an interval to keep waiting for it to finish because this is a MASSIVE report
        // we just need to wait for the initial file, the cancellation file will have to wait
        this.timerId = setInterval(this.getFileStatus, 1000);
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'error: ' + error;
        this.openModal('error');
      });
    },
    async getFileStatus() {
      this.spinning = true;
      await filesAPIHandler.isFileReady(this.nova_excel_filename, this.$router)
      .then((resp) => {
        if (resp.success === true) {
          this.activationRan = true;
          this.shortName = resp.shortName;
          clearInterval(this.timerId);
          this.spinning = false;
        }
      })
      .catch((error) => {
        this.error_txt = 'File status call failed!: ' + error;
        this.openModal('error');
        clearInterval(this.timerId);
        this.spinning = false;
      });
    },
    customFormatter(date) {
      return moment(date).utc().format('YYYY-MM-DD');
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
  }
}
</script>

<style scoped>

</style>
