import { CommonAPIService } from './CommonHandler';

export class MenuOptionsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  get(router) {
    const url = `/api/menu_options`;
    return this.CommonAPIService.getCall(url, null, router);
  }
  create(menu_option, router) {
    const url = `/api/menu_options`;
    return this.CommonAPIService.postCall(url, menu_option, router);
  }
  delete(id, router) {
    const url = `/api/menu_options/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }
  update(id, menu_option, router) {
    const url = `/api/menu_options/${id}`;
    return this.CommonAPIService.putCall(url, menu_option, router);
  }
}

export default MenuOptionsAPIService;
